<template>
  <div>
    <b-modal v-model="modalShow" size="md" :title="$t('permission.title.data', [entity !== null ? $t(`permission.${entity.toLowerCase()}`) : null])" 
      footer-class="footerClass"
      @hidden="$emit('update:show', false)"
      no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
      :ok-title="$t('button.select')"
    >
      <b-alert :variant="alertError? 'danger':'success'" dismissible :show="showError" @dismissed="dismissAlert">
        <font-awesome-icon :icon="alertError? ['fas', 'triangle-exclamation'] : ['far', 'check']"/>&nbsp;&nbsp;{{ alertMsg }}
      </b-alert>
  
      <div v-if="departmentRuleApplied">
        <span>{{$t("permission.departmentRule")}}</span>
      </div>
      <div v-else-if="customerRuleApplied">
        <span>{{$t("permission.customerRule")}}</span>
      </div>
      <div v-else>
        <b-form-group :label="$t('permission.include')">
          <b-form-radio-group
            id="radio-group-1"
            v-model="operator"
            :options="operators"
            name="operator"
            @change="operatorChanged"
          ></b-form-radio-group>
        </b-form-group>
        
        <label>{{$t('permission.except')}}</label>
        <b-btn :id="`BTN_CLEAR_SELECTED`" class="d-inline-block btn-action" @click="clear()"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
        <b-popover
          :target="`BTN_CLEAR_SELECTED`"
          placement="top"
          boundary="viewport"
          triggers="hover"
          :content="$t('button.clear_all')">
        </b-popover>
        <b-form-group>
          <b-input-group v-for="(rule,index) in editDataRules" :key="index">
            <b-dropdown lazy class="permission-value-dropdown" :class="filterValuesText !== '' ? 'notempty-permission-value-dropdown' : ''" :text="filterValuesText">
              <b-form-input v-if="selectOptions.length !== 0" v-model="listFilter" :placeholder="$t('filter_component.search')"/>
              <b-dropdown-form>
                <b-form-checkbox-group v-model="selected" :options="filteredSelectOptions" @change="checkboxChanged" stacked></b-form-checkbox-group>
              </b-dropdown-form>
            </b-dropdown>
          </b-input-group>
        </b-form-group>
      </div>      
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button v-if="!readOnly && !departmentRuleApplied && !customerRuleApplied" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t(departmentRuleApplied || customerRuleApplied ? 'button.close' : 'button.cancel') }}</b-button>
        
      </template>
    </b-modal>

  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { strRandom, isBlacklistedPermission } from '@/helpers';
import { queryService } from '@/services';

export default {
  name: 'DataPermissionModal',
  components: {
  },
  props: {
    show:         { type: Boolean, required: true },
    isEdit:       { type: Boolean, default: false },
    isAdd:        { type: Boolean, default: false },
    entity:       { type: String, default: null },
    schema:       { type: Object, default: () => { return {} } },
    macros:       { type: Object, default: () => { return {} } },
    dataRules:    { type: Array, default: () => { return [] } },
    readOnly:     { type: Boolean, default: false },
    staffRuleApplied: { type: Boolean, default: false },
    projectRuleApplied: { type: Boolean, default: false }
  },
  data() {
    return {
      id: `PERMISSION_MODAL_${strRandom(5)}`,
      editDataRules: null,
      alertError: false,
      alertMsg: null,
      modalShow: false,
      predicate: {},
      userId: null,
      selectValue: null,
      selectOptions: [],
      selected: [],
      updateTick: 0,
      operator: 'is_not',
      listFilter: null
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.editDataRules = cloneDeep(this.dataRules);
        if (this.editDataRules.length === 0) {
          this.editDataRules.push('');
        }
        this.selected = [];
        
        // get the user id
        this.userId = this.$store.state.authentication.user.uuId;
        this.resetValues();
        this.alertMsg = null;
        this.modalShow = newValue;
        this.listFilter = null;
        
        if (this.staffRuleApplied) {
          this.alertMsg = this.$t('permission.staffRule');
          this.alertError = true;
        }
        else if (this.projectRuleApplied) {
          this.alertMsg = this.$t('permission.projectRule');
          this.alertError = true;
        }
        
        this.operator = this.editDataRules[0].includes(' eq ') || this.editDataRules[0].includes('within') ? 'is' : 'is_not';
        
        const fields = this.entity === 'DEPARTMENT' ? [`${this.entity}.uuId`, `${this.entity}.name`, [["=fullPath(A)", [`${this.entity}.name`, "---"]], "", "rcombine"]]
                       : this.entity === 'STAFF' ? [`${this.entity}.uuId`, `${this.entity}.name`, `${this.entity}.genericStaff`]
                       : [`${this.entity}.uuId`, `${this.entity}.name`]                     
        queryService.query({       
            name: `Data Permission query ${this.entity}`,
            start: 0, 
            limit: -1,
            ksort: `${this.entity}.name`,
            order: 'asc'
        }, fields, null, { type: 'nominate', field: this.entity}, null, null, null)
        .then(response => {
          const data = response.data[response.data.jobCase];
          if (typeof data === 'undefined') {
            return null;
          }
          
          if (this.entity === 'DEPARTMENT') {
            this.selectOptions = data.map(d => { return { value: d[0], shorttext: d[1], text: d[2][0], checked: this.editDataRules.length > 0 && this.editDataRules[0].includes(d[0]) ? this.selected.push(d[0]) : false}});
            this.selectOptions.sort((a, b) => {
              return a.text.localeCompare(b.text);
            });
          }
          else if (this.entity === 'STAFF') {
            this.selectOptions = data.map(d => { return { value: d[0], shorttext: d[1], text: d[2] ? `(Generic) ${d[1]}` : d[1], checked: this.editDataRules.length > 0 && this.editDataRules[0].includes(d[0]) ? this.selected.push(d[0]) : false}});
          }
          else {
            this.selectOptions = data.map(d => { return { value: d[0], shorttext: d[1], text: d[1], checked: this.editDataRules.length > 0 && this.editDataRules[0].includes(d[0]) ? this.selected.push(d[0]) : false}});
          }
        })
        .catch(function(error) {
          console.error(error); // eslint-disable-line no-console
        });
      }
    }
  },
  computed: {
    filteredSelectOptions() {
      if (this.listFilter) {
        return this.selectOptions.filter(v => v.text.toLowerCase().indexOf(this.listFilter.toLowerCase()) !== -1);
      }
      return this.selectOptions;
    },
    operators() {
      return [
        { text: this.$t('all'), value: 'is_not' },
        { text: this.$t('none'), value: 'is' }
      ]
    },
    departmentRuleApplied() {
      return this.editDataRules && this.editDataRules.length > 0 && this.editDataRules[0].startsWith('.DEPARTMENT');
    },
    customerRuleApplied() {
      return this.editDataRules && this.editDataRules.length > 0 && (this.editDataRules[0].startsWith('.CUSTOMER') || this.editDataRules[0].startsWith('.PROJECT.CUSTOMER'));
    },
    showUserCheckbox() {
      return this.entity === 'TASK' ||
          this.entity === 'BOOKING' ||
          this.entity === 'SKILL' ||
          this.entity === 'RESOURCE' ||
          this.entity === 'LOCATION' ||
          this.entity === 'COMPANY' ||
          this.entity === 'DEPARTMENT' ||
          this.entity === 'NOTE' ||
          this.entity === 'ACTIVITY' ||
          this.entity === 'TAG' ||
          this.entity === 'STORAGE_FILE' ||
          this.entity === 'USER' ||
          this.entity === 'PROJECT';
    },
    showError() {
      return this.alertMsg != null;
    },
    filterValuesText() {
      // force update
      this.updateTick;
    
      const vals = this.selectOptions.filter(f => this.selected.includes(f.value));
      let text = '';
      for (const val in vals) {
        if (text.length > 0) {
          text += ', ';
        }
        text += vals[val].shorttext;
      }
      return text;
    },
  },
  methods: {
    async resetValues() {
      
      this.predicate = await this.$store.dispatch('data/info', { type: 'predicate' }).then((value) => { 
        return value;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        return [];
      });
      
      this.macros = await this.$store.dispatch('data/info', { type: 'macro' }).then((value) => { 
        return value;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        return [];
      });
      
    },
    removeEntry(index) {
      this.editDataRules.splice(index, 1);
    },
    ok() {
      this.errors.clear();
      this.alertMsg = null;
      this.alertError = false;
      
      if (this.operator === 'is' &&
          this.selected.length === 0) {
        this.alertMsg = this.$t('permission.no_rules');
        this.alertError = true;
        return;    
      }
      
      if (this.selected.length === this.selectOptions.length) {
        this.alertMsg = this.operator === "is_not" ? this.$t('permission.all_options_none') : this.$t('permission.all_options');
        this.alertError = true;
        return;    
      }
      
      if (this.selected.length > 200) {
        this.alertMsg = this.$t('permission.too_many');
        this.alertError = true;
        return;    
      }
      
      this.$emit('update:show', false);
      if (this.editDataRules.length === 1 &&
          this.editDataRules[0] === '') {
        this.editDataRules.splice(0, 1);   
      }
      
      this.$emit('success', this.editDataRules);
    },  
    dismissAlert() {
      this.alertMsg = null;
      this.alertError = false;
    },
    addRule() {
      this.editDataRules.push('');
    },
    updateRule() {
      const checked = this.selectOptions.filter(f => this.selected.includes(f.value));
      if (checked.length === 1) {
        this.$set(this.editDataRules, 0, `uuId ${this.operator === 'is' ? 'eq' : 'neq'} ${checked[0].value}`);
      }
      else if (checked.length > 0) {
        let within = [];
        for (const val of checked) {
          within.push(val.value);
        }
        this.$set(this.editDataRules, 0, `uuId ${this.operator === 'is' ? 'within' : 'without'} ${within.join('|')}`);
      }
      else {
        this.$set(this.editDataRules, 0, '');
      }
    },
    operatorChanged() {
      this.updateRule();
    },
    checkboxChanged(index) {
      this.updateTick++;
      this.updateRule();
    },
    clear() {
      this.selected.splice(0, this.selected.length);
      this.updateRule();
    }
  }
}
</script>

<style lang="scss">
.field-modal {
  min-height: 350px;
}

.editing-field {
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 0 0 0.2rem var(--form-control-focus-shadow);
  box-shadow: 0 0 0 0.2rem var(--form-control-focus-shadow);
}

.action-button {
  margin: 4px;
}

.field-select-container {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--form-control-border);
  border-radius: 3px;
  padding: 3px;
}

.field-dot {
  display: inline-block;
  font-weight: bold;
  vertical-align: text-bottom;
  margin: 2px;
}

.field-label {
  padding: 7px;
  margin-right: 5px;
}

.column-list {
  display: inline-block;
  margin: 1px;
  border: 1px solid var(--border-medium);
  border-radius: 3px;
}

.child-entity {
  font-style: italic;
}

.content-tab {
  max-height: 300px;
  overflow-y: auto;
}

.root-edit {
  background-color: var(--form-control-disabled-bg);
}

.child-entity {
  font-style: italic;
}

.ag-row-selected::before,
.ag-row-selected.ag-row-hover::before {
  background-color: transparent;
}


.permission-value-dropdown li {
  width: 460px;
}

.permission-value-dropdown ul {
  max-height: 400px;
  overflow-y: scroll;
}

.permission-value-dropdown button {
  width: 470px;
  text-align: left;
  background: transparent;
  height: 35px;
}

@media only screen and (max-width: 470px) {
  .permission-value-dropdown button {
    width: 330px;
  }
    
  .permission-value-dropdown li {
    width: 330px;
  }
  
  .filter-list li a {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.history-dropdown {
  flex-shrink: 0;
}

.permission-value-dropdown.show > .btn-secondary.dropdown-toggle,
.history-dropdown.show > .btn-secondary.dropdown-toggle {
  color: var(--text-light);
  background-color: var(--surface-bg);
  border-color: var(--border-light);
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}

.permission-value-dropdown .btn-secondary,
.permission-value-dropdown .btn-secondary:hover {
  background-color: transparent;
  border-color: var(--form-control-border);
  color: var(--text-light);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
}
  
.history-dropdown .btn-secondary,
.history-dropdown .btn-secondary:hover,
.history-dropdown .btn-secondary:focus  {
  background-color: transparent;
  border: none;
  color: var(--text-light);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
  box-shadow: none;
}
  
.notempty-permission-value-dropdown .dropdown-toggle::after,
.history-dropdown .dropdown-toggle::after {
  top: 8px;
}

.permission-value-dropdown .dropdown-toggle::after {
  position: relative;
  float: right;
  border-top-color: var(--gray-500);
}

</style>
